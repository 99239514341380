import {
  Box,
  TableContainer,
  TableBody,
  TableCell,
  Table,
  TableRow,
  Typography,
} from "@mui/material";
import formatRupiah from "../helper/Rupiah";
import { Info } from "./Info";
import { Motion } from "./Motion";

export const Cost = () => {
  const data = [
    { desc: "Uang Pangkal Pesantren", price: 8500000 },
    { desc: "Uang IPP/bulan<sup>(1)</sup>", price: 700000 },
    { desc: "Uang Makan/Bulan<sup>(2)</sup>", price: 520000 },
    { desc: "Uang Laundry<sup>(3)</sup>", price: 90000 },
    { desc: "Uang sewa lemari", price: 550000 },
    { desc: "Uang buku paket wajib kelas 1", price: 567000 },
    { desc: "Uang perlengkapan putra<sup>(4)</sup>", price: 565000 },
    { desc: "Uang perlengkapan putri<sup>(5)</sup>", price: 835000 },
    // {desc: 'Uang laundry/bulan<sup>(2)</sup>', price: 80000},
    // {desc: 'Uang makanan ringan/bulan<sup>(2)</sup>', price: 120000},
    // {desc: 'Uang Kasur ', price: 210000},
    // {desc: 'Uang kaos olahraga putra', price: 120000},
    // {desc: 'Uang kaos olahraga putri', price: 155000},
    // {desc: 'Uang seragam gamis putri', price: 235000},
    // {desc: 'Uang bet seragam PP Darussalam', price: 20000},
    // {desc: 'Uang tas laundry', price: 25000},
    // {desc: 'Uang tas sandal', price: 15000},
  ];
  return (
    <Motion>
      <Box
        sx={{
          minHeight: { sm: "70vh", xs: "65vh" },
          bgcolor: "white",
          borderRadius: { sm: "40px 40px 0 0", xs: "20px 20px 0 0" },
          display: "flex",
          justifyContent: "center",
          padding: { xs: "20px 0 0", sm: "40px 0 0" },
          position: "relative",
          zIndex: 2,
        }}
      >
        <Info desc="Rincian Biaya Pendaftaran TP 2024/2025">
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <TableContainer>
              <Table>
                <TableBody>
                  {data.map((item, idx) => (
                    <TableRow
                      key={idx}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell>
                        <Typography
                          sx={{ fontSize: { xs: "10px", sm: "14px" } }}
                          dangerouslySetInnerHTML={{ __html: item.desc }}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <Typography
                          sx={{
                            fontSize: { xs: "10px", sm: "14px" },
                            fontWeight: "bold",
                          }}
                        >
                          {formatRupiah(item.price)}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                alignItems: "start",
              }}
            >
              <Typography
                sx={{
                  fontSize: { xs: "10px", sm: "14px" },
                  margin: "10px 0 0",
                }}
              >
                Total Biaya Calon Santri Putra : Rp. 11.492.000,-
              </Typography>
              <Typography
                sx={{
                  fontSize: { xs: "10px", sm: "14px" },
                  margin: "0 0 10px",
                }}
              >
                Total Biaya Calon Santri Putri : Rp. 11.762.000,-
              </Typography>
              <Typography sx={{ fontSize: { xs: "10px", sm: "14px" } }}>
                <sup>(1)</sup>Uang IPP (Infak Pondok Pesantren) di atas sudah
                meliputi : asrama, air, kursus bahasa arab-inggris dan KBM.
              </Typography>
              <Typography
                sx={{
                  fontSize: { xs: "10px", sm: "14px" },
                  padding: "10px 0 0",
                }}
              >
                <sup>(2)</sup>Makan sehari 3 kali (nasi & lauk pauk)
              </Typography>
              <Typography
                sx={{
                  fontSize: { xs: "10px", sm: "14px" },
                  padding: "10px 0 0",
                }}
              >
                <sup>(3)</sup>Laundry 12 kg (hanya wajib untuk santri baru)
              </Typography>
              <Typography
                sx={{
                  fontSize: { xs: "10px", sm: "14px" },
                  padding: "10px 0 0",
                }}
              >
                <sup>(4)</sup>Uang perlengkapan putra meliputi: Kasur, bet
                seragam, tas laundry, tas sandal, sandal, wallpaper lemari, buku
                perpulangan, name tag, KTA, gembok dan kaos olahraga
              </Typography>
              <Typography
                sx={{
                  fontSize: { xs: "10px", sm: "14px" },
                  padding: "10px 0 0",
                }}
              >
                <sup>(5)</sup>Uang perlengkapan putri meliputi: Kasur, bet
                seragam, tas laundry, tas sandal, sandal, wallpaper lemari, buku
                perpulangan, name tag, KTA, gembok, kaos olahraga dan gamis
              </Typography>
            </Box>
          </Box>
        </Info>
      </Box>
    </Motion>
  );
};
